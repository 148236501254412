<template lang="pug">
  div.row
    template(v-if="site_info.Label")
      div.col-12
        h5.mb-1 {{ site_info.Label }}
        p {{ valid? "監視中":"停止中" }} &nbsp;&nbsp; ID:{{ target_id }} &nbsp;&nbsp;User:{{ user_id }} &nbsp;&nbsp;No.{{ no }}
          a.ms-3(:href="site_info.Url", target="_blank", rel="noopener") {{ site_info.Url }}
      div.col-12      
        h6 Detail
        button.btn.btn-primary(v-if="detail.id===0", type="button", @click="get_detail") Load
        div.row(v-else)
          div.col-12.col-md-3
            table.table.table-sm.caption-top
              caption Basic
              tbody
                tr
                  th ID
                  td {{ target_id }}
                tr
                  th User
                  td {{ user_id }}
                tr
                  th No
                  td {{ no }}
                tr
                  th NodeID
                  td {{ detail.node_id }}
                tr
                  th Timing
                  td {{ detail.timing_flag }}
                tr
                  th Time
                  td {{ detail.processing_time }}
                tr
                  th Created
                  td {{ detail.created | moment }}
                tr
                  th Updated
                  td {{ detail.updated | moment }}
          div.col-12.col-md-4
            table.table.table-sm.caption-top
              caption Options
              tbody
                tr
                  th Trigger
                  td
                    p.mb-0(v-for="ct in detail.options.CheckTriggers") {{ trigger_types[ct.TriggerType] }}: {{ ct.Value }}
                tr
                  th Timeout
                  td {{ detail.options.TimeOut }}
                tr
                  th WaitTime
                  td {{ detail.options.WaitTime }}
                tr
                  th MailNotify
                  td <svg v-show="detail.options.MailNotify" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th MailTarget
                  td 
                    p.mb-0(v-if="detail.options.MailTarget.length", v-for="m in detail.options.MailTarget") {{ m }}
                tr
                  th LINENotify
                  td <svg v-show="detail.options.LINENotify" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th ScreenShot
                  td <svg v-show="detail.options.ScreenShot" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th RandomIP
                  td <svg v-show="detail.options.RandomIP" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>

          div.col-12.col-md-4
            table.table.table-sm.caption-top
              caption DiffOptions
              tbody
                tr
                  th RemoveTag
                  td <svg v-show="detail.diff_options.RemoveTag" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th IgnoreDate
                  td <svg v-show="detail.diff_options.IgnoreDate" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th IgnoreTime
                  td <svg v-show="detail.diff_options.IgnoreTime" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th IgnoreNum
                  td <svg v-show="detail.diff_options.IgnoreNum" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                tr
                  th IgnoreWords
                  td.l
                    p.mb-0(v-for="w in detail.diff_options.IgnoreWords") "{{ w }}", 
                tr
                  th IgnoreReWords
                  td.l
                    p.mb-0(v-for="w in detail.diff_options.IgnoreReWords") "{{ w }}", 

          div.col-12
            div.table-responsive
              table.table.table-sm.caption-top
                caption SiteInfo
                thead
                  tr
                    th.l Type
                    th.l Label
                    th.l URL
                    th.l UA
                tbody
                  tr
                    td.l {{ site_types[detail.site_info.SiteType] }}
                    td.l {{ detail.site_info.Label }}
                    td.l {{ detail.site_info.Url }}
                    td.l {{ detail.site_info.UserAgent }}



          div.col-12
            div.table-responsive
              table.table.table-sm.caption-top
                caption Targets
                thead
                  tr
                    th Area_ID
                    th Label
                    th URL
                    th Type
                    th Value
                tbody
                  tr(v-for="t in detail.targets", :key="t.area_id")
                    td {{ t.area_id }}
                    td {{ t.label }}
                    td
                      input(type="text", :value="t.url", @focus="select")
                    td 
                      a.link(@click="js(t)") {{ t.type }}
                    td
                      input(type="text", :value="t.value")
          div.col-12()
            p.mb-0.text-muted Conditions
            ConditionsCard.mt-3(v-if="detail.conditions", :conditions="detail.conditions")
            p.ms-3(v-else) No conditions

      div.col-12.mt-3
        h6 最新チェック100件
        button.btn.btn-primary(v-if="histories.length===0", type="button", @click="get_histories") Load
        div.table-responsive(v-else)
          table.table.table-sm.table-histories
            thead
              tr
                th.id ID
                th.moment At
                th.check Changed
                th.check Notified
                th.check Error
                th.status Status
                th.moment VS
                th <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image" data-v-12cac6b8=""><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
            tbody
              tr(v-for="h in histories", :key="h.id", :class="h.error? 'table-danger':h.changed? 'table-success':''")
                td {{ h.id }}
                td {{ h.checked | moment }}
                td <svg v-show="h.changed" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                td <svg v-show="h.notified" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                td <svg v-show="h.error" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle" data-v-12cac6b8=""><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                td {{ h.status }}
                td {{ h.vs | moment }}
                td
                  a.mx-2.pointer(@click="show_headers(h.id)") HEADERS
                  a.mx-2.pointer(v-if="h.changed", @click="show_diffs(h.id)") DIFFS
                  a.mx-2(v-if="h.new_ss",:href="APP_BASE_URL+'/screenshots/ss/'+h.new_ss", target="_blank") NEW
                  a.mx-2(v-if="h.old_ss",:href="APP_BASE_URL+'/screenshots/ss/'+h.old_ss", target="_blank") OLD

    div.text-start.d-none#history
      div(v-for="area in diffs", :key="area.area_id")
        p.mb-0 エリア{{ area.area_id }} &nbsp;&nbsp; {{ area.label }}
        p.mt-3.mb-0 NEW
        p.mb-0(v-for="n in area.news") {{ n }}
        p.mt-3.mb-0 OLD
        p.mb-0(v-for="o in area.olds") {{ o }}
</template>

<script>
import moment from 'moment';
import ConditionsCard from '@/components/ConditionsCard.vue';

export default {
  name:"Histories",
  components:{
    ConditionsCard
  },
  data (){
    return {
      target_id:0,
      histories:[
      ],
      site_info:{
        label:"",
        url:""
      },
      user_id:"",
      no:"",
      APP_BASE_URL:process.env.VUE_APP_BASE_URL,
      valid:false,
      diffs:[],
      history_div:null,
      detail:{ id:0 },
      site_types:[
        "Static",
        "Dynamic",
        "Protected",
        "RSS"
      ],
      trigger_types:[ "","Interval", "Cron" ],
    }
  },
  mounted (){
    this.target_id = Number(this.$route.query.id);
    this.axios.get("/api/histories?basic&id="+this.target_id)
    .then((res)=>{
      this.site_info = res.data.site_info;
      this.user_id = res.data.user_id;
      this.no = res.data.no;
      this.valid = res.data.valid;
    });
    this.history_div = document.getElementById("history");
    this.history_div.remove();
    this.history_div.classList.remove("d-none");
  },
  methods: {
    get_detail (){
      this.axios.get("/api/histories?detail&id="+this.target_id)
      .then((res)=>{
        this.detail = res.data;
      })
      .catch((e)=>{
        console.log(e);
        console.log(e.response);
        this.$swal("","","error");
      });
    },
    get_histories (){
      this.axios.get("/api/histories?all&id="+this.target_id)
      .then((res)=>{
        let histories = res.data.histories;
        histories.sort((a,b)=>b.checked-a.checked);
        this.histories = histories;
      })
      .catch((e)=>{
        console.log(e);
        console.log(e.response);
        this.$swal("","","error");
      });
    },
    show_diffs (id){
      this.axios.get("/api/history?diffs&id="+id)
      .then((res)=>{
        this.diffs = res.data;
        this.$swal({ content:this.history_div, buttons:[false, "close"] });
      });
    },
    show_headers (id){
      this.axios.get("/api/history?headers&id="+id)
      .then((res)=>{
        this.$swal(JSON.stringify(res.data, null, 2), { buttons:[false, "close"] });
      });
    },
    js (t){
      let input = document.createElement("input");
      input.setAttribute("type","text");
      input.setAttribute("readonly", true)
      input.classList.add("form-control");
      if (t.type=="xpath")
        input.value = `document.evaluate("${t.value}", document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null).snapshotItem(0)`;
      else
        input.value = `document.querySelector("${t.value}")`;
      input.addEventListener("focus", (e)=>{ e.target.select(); document.execCommand("copy"); });
      this.$swal({ content:input });
    },
    select (e){
      e.target.select();
      document.execCommand("copy");
    }
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.table-histories {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
  .id {
    width: 90px;
  }
  .moment {
    width: 150px;
  }
  .check {
    width: 80px;
  }
  .status {
    width: 70px;
  }
}
.pointer {
  cursor: pointer;
}

th, td {
  vertical-align: middle;
  text-align: center;
  &.l {
    text-align: left;
  }
}

td input[type="text"] {
    width: 100%;
    border: none;
    text-align: center;
}

.link {
  color: blue!important;
  text-decoration: underline;
}
</style>