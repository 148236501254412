<template lang="pug">
  div
    div.card(:class="conditions.logical===1? 'and-card':'or-card'")
      div.card-body.p-2
        h4.mb-0 {{ conditions.logical===1? "AND":"OR" }}
        div.d-grid.gap-2.mb-2(v-for="c in conditions.conditions")
          button.btn.btn-outline-dark(type="button", v-show="c.type_==1") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}が "{{ c.value }}" を含む
          button.btn.btn-outline-dark(type="button", v-show="c.type_==2") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}が "{{ c.value }}" を含む
          button.btn.btn-outline-dark(type="button", v-show="c.type_==3") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}の数値が "{{ c.value }}" 以上
          button.btn.btn-outline-dark(type="button", v-show="c.type_==4") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}の数値が "{{ c.value }}" 未満
          button.btn.btn-outline-dark(type="button", v-show="c.type_==5") エリア{{ c.area }} の数値が "{{ c.value }}" 以上増加
          button.btn.btn-outline-dark(type="button", v-show="c.type_==6") エリア{{ c.area }} の数値が "{{ c.value }}" 以上減少
          button.btn.btn-outline-dark(type="button", v-show="c.type_==7") エリア{{ c.area }} の数値が "{{ c.value }}" %以上増加
          button.btn.btn-outline-dark(type="button", v-show="c.type_==8") エリア{{ c.area }} の数値が "{{ c.value }}" %以上減少
          button.btn.btn-outline-dark(type="button", v-show="c.type_==9") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}が "{{ c.value }}" にマッチする部分を含む
          button.btn.btn-outline-dark(type="button", v-show="c.type_==10") エリア{{ c.area }} の{{ c.target==1? "全文":(c.target==2? "追加分":"削除分") }}が "{{ c.value }}" にマッチする部分を含まない
          ConditionsCard(v-if="c.type_==0", :conditions="c.value")
</template>

<script>
import ConditionsCard from '@/components/ConditionsCard.vue'
export default {
  name: 'ConditionsCard',
  components: {
    ConditionsCard
  },
  props:[
    "conditions"
  ],
}
</script>

<style lang="scss" scoped>
.and-card {
  background-color: #fdefe4;
  border-color: #fad9bf;
  >div>div>div>.and-card {
    background-color: #fbe4d2;
    border-color: #f8cead;
    >div>div>div>.and-card {
      background-color: #fad9bf;
      border-color: #f7c39b;
    }
  }
  h4 {
    color: #003e71;
  }
}
.or-card {
  background-color: aliceblue;
  border-color: #d3ebff;
  :not(.and-card) .or-card {
    background-color: #e4f3ff;
    :not(.and-card) .or-card {
      background-color: #d3ebff;
      border-color: #bfe2ff;
    }
  }
  h4 {
    color: #643008;
  }
}
</style>